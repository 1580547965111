/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2"
  }, _provideComponents(), props.components), {HrefLangManager, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aids/activities-that-help-you-adjust-to-new-hearing-aids/'], ['en', 'https://www.hear.com/resources/hearing-aids/activities-that-help-you-adjust-to-new-hearing-aids/'], ['en-US', 'https://www.hear.com/resources/hearing-aids/activities-that-help-you-adjust-to-new-hearing-aids/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aid-success/activities-that-help-you-adjust-to-new-hearing-aids/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "activities-that-help-you-adjust-to-new-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#activities-that-help-you-adjust-to-new-hearing-aids",
    "aria-label": "activities that help you adjust to new hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Activities That Help You Adjust to New Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/adjust-to-hearing-aids.png",
    alt: "people having a conversation",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Adjusting to your new hearing aids is a process and it’s important to take it slow. If you haven’t read our article, ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/why-slow-and-steady-wins-the-race-with-new-hearing-aids/",
    className: "c-md-a"
  }, "Why Slow & Steady Wins the Race With New Hearing Aids"), ", you’ll want to read it so you set yourself up for success.  But once you start getting more comfortable in quieter environments and increasing your wearing time, it’s good to explore different situations to continue your progress. Here’s a list of activities that can ease you into more challenging listening situations to keep things interesting. The list is ranked from the easiest to hardest — just remember to take it slow."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "have-you-turned-down-the-volume-on-the-tv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#have-you-turned-down-the-volume-on-the-tv",
    "aria-label": "have you turned down the volume on the tv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Have you turned down the volume on the TV?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ask someone to set the TV volume to “normal,” because you shouldn’t need to have it louder than a person with normal hearing. Then try to use that setting consistently. A great way to help your brain reconnect sounds to language is to watch movies or shows with captions or subtitles. Because listening and seeing at the same time helps strengthen those connections."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "try-a-group-conversation-in-a-quiet-setting-with-2-or-3-people",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#try-a-group-conversation-in-a-quiet-setting-with-2-or-3-people",
    "aria-label": "try a group conversation in a quiet setting with 2 or 3 people permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Try a group conversation in a quiet setting with 2 or 3 people"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Start with close friends or family, since familiar voices are easier to identify. Make sure you’re facing the person speaking and practice active listening. This helps your brain reconnect the dots between sounds, patterns, and nonverbal cues."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/two-people-conversation.png",
    alt: "Two person having a conversation",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "find-a-quiet-place-outside-like-a-backyard",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#find-a-quiet-place-outside-like-a-backyard",
    "aria-label": "find a quiet place outside like a backyard permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Find a quiet place outside, like a backyard"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s amazing how noisy a quiet place outdoors can be. Get used to the sound of the wind. Listen to the sounds of birds, leaves rustling, traffic going by, etc. In addition to hearing these sounds, try to locate them, too. As you get more comfortable, close your eyes and try to locate the sounds without looking."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "have-a-phone-conversation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#have-a-phone-conversation",
    "aria-label": "have a phone conversation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Have a phone conversation"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Because you can’t see the person’s face and don’t have other nonverbal cues, phone conversations can be more challenging. Remind the person on the other end to speak clearly and at a moderate pace. If your hearing aids are ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth enabled"), ", try streaming the audio directly to your hearing aids. You can check out ", React.createElement(_components.a, {
    href: "/resources/hearing-aids/how-to-connect-hearing-aids-to-smartphone/",
    className: "c-md-a"
  }, "this article"), " if you need help connecting them. If you can’t stream audio directly to your hearing aid, try positioning the phone closer to your hearing aid’s microphone and experimenting with different angles."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/phone-conversation.png",
    alt: "Person having a phone conversation",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "create-a-noisy-environment-at-home-and-have-a-conversation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#create-a-noisy-environment-at-home-and-have-a-conversation",
    "aria-label": "create a noisy environment at home and have a conversation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Create a noisy environment at home and have a conversation"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All you need to do is turn on the TV or radio to a normal volume and have a conversation with others in the same room. Try to focus on the conversation and tune out the background noise."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "go-for-a-drive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#go-for-a-drive",
    "aria-label": "go for a drive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Go for a drive"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Even more than the outdoors, your car is filled with noise. Listen to the sound of your engine, the road, the wind, and more. (But hopefully, you don’t hear too many problems with your car that you didn’t notice before — like squeaky brakes.) It might be harder to have a conversation at first, but that’s okay. With time, your brain will adjust and you won’t focus on the background noises."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "meet-a-friend-at-the-local-coffee-shop-or-a-quiet-restaurant",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#meet-a-friend-at-the-local-coffee-shop-or-a-quiet-restaurant",
    "aria-label": "meet a friend at the local coffee shop or a quiet restaurant permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Meet a friend at the local coffee shop or a quiet restaurant"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Find a good space that minimizes background noise. A corner booth is perfect, but something away from the clanging of the kitchen will suffice. Be patient, and if it’s ever uncomfortable, just turn your hearing aids off or take them out. Remember, this is a journey and it takes time to adjust. But hopefully, you can enjoy some good conversation and pick up on some local gossip while you’re at it."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/chat-at-local-cofffe-shop.png",
    alt: "women in pain due to ear infection",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "were-always-available",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#were-always-available",
    "aria-label": "were always available permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We’re always available"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have questions, concerns, or need help on your journey to better hearing, please give us a call at 888-780-3450. We’re here to guide you and make sure you’re successful with your hearing aids."), "\n", React.createElement(LandingPageCta, {
    copy: "We are always available",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
